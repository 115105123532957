import { lazy } from 'react';

//Prefetching to have the content ready for when the user switches to other pages
//Lazy loading to have the page seem more responsive faster, by having something show up, instead of just a blank page
export const Login = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "Login" */
    '../components/LoginForm/loginFormIndex.jsx'
  )
);

export const Landing = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "Landing" */
    '../components/landingPage/landing'
  )
);

export const FactoryDetail = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "FactoryDetail" */
    '../components/factoryDetail'
  )
);

export const ErrorLanding = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "ErrorLanding" */
    '../components/errorStuff/errorLanding/errorLandingIndex.jsx'
  )
);

export const DashboardLanding = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "DashboardLanding" */
    '../components/dashboardLanding/dashboardLandingIndex'
  )
);

export const InsightsLanding = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "InsightsLanding" */
    '../components/insightsLanding/insightsLandingIndex'
  )
);

export const PressedTagAlert = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "PressedTagAlert" */
    '../components/pressedTagAlert/pressedTagAlertIndex.jsx'
  )
);

export const AreaGeneratorLanding = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "AreaGeneratorLanding" */
    '../components/Settings/AreaGeneratorLanding'
  )
);

export const UnauthorisedLanding = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "UnauthorisedLanding" */
    '../components/Unauthorised'
  )
);

export const ApsLanding = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "ApsLanding" */
    '../components/aps/apsLanding/apsLandingIndex'
  )
);

export const SettingsLanding = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "SettingsLanding" */
    '../components/Settings/settingsLanding/SettingsLanding.jsx'
  )
);

export const Notification = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "Notification" */
    '../components/bars/Notification'
  )
);
