/**
 * Context for Insights page
 *
 * example usage:
 *
 * const { insightContext, updateInsightContext } = useContext(InsightContext);
 * updateInsightContext({workerSharedKeys: [{1: 'temp'}]})
 *
 */

import { useState, createContext, useEffect, useContext, useRef, useCallback } from 'react';
import AppContext from '../context/AppContext.js';
import helper from '../util/helper.js';

const InsightContext = createContext();

// used in App.js - provider used to wrap components context is required for
const InsightContextProvider = ({ children }) => {
  const { accessToken, controllerURLs, user, } = useContext(AppContext);
  const [insight_context, setInsightContext] = useState({
    savedKeys: null,//keep track of what's in the db at load
    initialSavedKeys: null,//to determine if we need to overwrite all other keys with ones from `savedKeys`
    workerSharedKeys: null, 
    workerIgnoredSharedKeys: null
  });

  const initialLoadBlock = useRef({workerGlobal: false});

  // fetches, sets, and sends POST request for userSaved keys
  // TODO: we should move this elsewhere and keep this file isolated to only InsightContext logic
  useEffect(() => {
    if (!insight_context.savedKeys) {
      return;
    }
    //initial setting of saved keys
    else if (insight_context.savedKeys && insight_context.initialSavedKeys) {
      setInsightContext((curr_context) => {
        let new_context = { ...curr_context };
        new_context.initialSavedKeys = false;
        //201 is the id for global employee keys
        if (new_context.savedKeys[201]) {
          let newWorkerIgnoreKeys = { ...new_context.workerIgnoredSharedKeys };
          for (const [key] of Object.entries(newWorkerIgnoreKeys)) {
            newWorkerIgnoreKeys[key] = false;
          }
          new_context.savedKeys[201][1].forEach((val) => {
            newWorkerIgnoreKeys[val] = true;
          })
          new_context.workerIgnoredSharedKeys = { ...newWorkerIgnoreKeys };
        }
        // new_context.workerSharedKeys = { ...new_context.savedKeys[200] };

        return new_context;
      })
      initialLoadBlock.current.workerGlobal = true;
    }
    else if(initialLoadBlock.current.workerGlobal){
      initialLoadBlock.current.workerGlobal = false;
    }
    else if(!initialLoadBlock.current.workerGlobal){
      //This will update the user selected keys for next visits
      const updateUserKeysLogic = (graphId, keyIndex, selectedKeys, grayKeys) => {

        let finalKeys = [];
        selectedKeys.forEach((key) => {
          if (grayKeys.includes(key)) {
            return;
          }
          finalKeys.push(key);
        });
        helper.POST(accessToken, controllerURLs.updateGraphKeys, {
          selectedKeys: finalKeys,
          keyIndex: keyIndex,
          graphId: graphId,
          userID: user.ID
        });
      };

      let workerIgnoredKeys = [];
      for (const [key, value] of Object.entries(insight_context.workerIgnoredSharedKeys)) {
        if (value) {
          workerIgnoredKeys.push(key)
        }
      }
      //201 is the id for global employee keys
      updateUserKeysLogic(201, 1, workerIgnoredKeys, []);
    }
  }, [accessToken, controllerURLs, user, insight_context])

  // Function to update the context value
  // copies old state and updates keys given in params
  const updateInsightContext = useCallback((newData) => {
    setInsightContext((prevData) => ({
      ...prevData,
      ...newData
    }));
  }, []);

  return (
    <InsightContext.Provider
      value={{
        insight_context,
        setInsightContext, // update the entire state object
        updateInsightContext // update specific keys in state object
      }}
    >{children}</InsightContext.Provider>
  );
};


export { InsightContext, InsightContextProvider };
