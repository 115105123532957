import { lazy, useContext } from 'react';

import AppContext from '../../../context/AppContext';
import useSideBarLogic from './sidebar';

const SideBarDesktop = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "SideBarDesktop" */ './desktop/Sidebar')
);
const SideBarMobile = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "SideBarMobile" */ './mobile/Sidebar')
);

export default function SideBar({ instance, isAuthenticated, apiAuthentication }) {
  const { mediaQueries } = useContext(AppContext);
  const logicVals = useSideBarLogic();

  if (mediaQueries.isTabletOrMobile)
    return (
      <SideBarMobile
        isAuthenticated={isAuthenticated}
        apiAuthentication={apiAuthentication}
        instance={instance}
        logicVals={logicVals}
      />
    );
  else
    return (
      <SideBarDesktop
        isAuthenticated={isAuthenticated}
        apiAuthentication={apiAuthentication}
        instance={instance}
        logicVals={logicVals}
      />
    );
}
