// Browser check variables
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// MSAL configuration
export const msalConfig = {
    auth: {
      authority: 'https://login.microsoftonline.com/common',
      tenantId: '5353dec4-e00e-49cf-9b18-f8e819b316f0',
      clientId: 'e2a5b007-70de-468e-8762-6fca2091a8d6',
      audience: 'e2a5b007-70de-468e-8762-6fca2091a8d6'
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    loadFrameTimeout: 30000
  };