import { useMediaQuery } from 'react-responsive';
import { useState, Suspense, useEffect, useRef } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import {
  Login,
  Landing,
  FactoryDetail,
  ErrorLanding,
  DashboardLanding,
  InsightsLanding,
  PressedTagAlert,
  AreaGeneratorLanding,
  UnauthorisedLanding,
  ApsLanding,
  SettingsLanding,
  Notification
} from './util/lazyComponents.js';
import Sidebar from './components/bars/sidebar/index.jsx';
import Topbar from './components/bars/topbar/index.jsx';
import helper from './util/helper.js';
import AppContext from './context/AppContext.js';
import useWebAuth from './authentication/useWebAuth.js';
import useTeamsAuth from './authentication/useTeamsAuth.js';
import useAndroidAuth from './authentication/useAndroidAuth.js';
import { config } from './config.js';
import useServerSentEvents from './hooks/useServerSentEvents.js';
import useLiveTrackingData from './hooks/useLiveTrackingData.js';
import { InsightContextProvider } from './context/InsightContext.js';

// for mobile app
// import useBackButton from './util/useBackButton.js';
// import { OAuth2Client } from '@byteowls/capacitor-oauth2';

const ROUTES = [
  // "/login",
  '/unauthorised',
  '/areaGenerator',
  '/detail',
  '/dashboard',
  '/error',
  '/settings',
  '/insight',
  '/aps'
];

const CONTROLLER_URLS = {
  getApplicationSettings: '/LTSAPI/Controller/1.0/applicationSettings',
  updateApplicationSettings: '/LTSAPI/Controller/1.0/updateApplicationSettings',
  assets: '/assets_stream/', // THIS ONE POINTS TO ltsDev !!! domain set in useWebAuth.js
  asset_map: '/LTSAPI/Controller/1.0/asset_map',
  workerStatus: '/LTSAPI/Controller/1.0/WorkerStatus',
  allowed: '/LTSAPI/Controller/1.0/Allowed',
  queryALL: '/LTSAPI/Controller/1.0/Tracker/queryALL',
  projectInfo: '/LTSAPI/Controller/1.0/Tracker/projectInfo',
  querySpecific: '/LTSAPI/Controller/1.0/Tracker/querySpecific',
  image: '/LTSAPI/Controller/1.0/Tracker/image',
  zone: '/LTSAPI/Controller/1.0/Tracker/zone',
  queryHistorical: '/LTSAPI/Controller/1.0/Tracker/queryHistorical',
  any: '/LTSAPI/Controller/1.0/Any',
  area: '/LTSAPI/Controller/1.0/Area',
  tag: '/LTSAPI/Controller/1.0/tag',
  tool: '/LTSAPI/Controller/1.0/Tool?ID=',
  toolType: '/LTSAPI/Controller/1.0/All?TableName=Tool_Type',
  worker: '/LTSAPI/Controller/1.0/Worker?ID=',
  material: '/LTSAPI/Controller/1.0/Material?ID=',
  component: '/LTSAPI/Controller/1.0/Component?ID=',
  components: '/LTSAPI/Controller/1.0/Components?Component_IDS=',
  queryPressed: '/LTSAPI/Controller/1.0/Tracker/queryPressed',
  allArea: '/LTSAPI/Controller/1.0/All?TableName=Area',
  allFactory: '/LTSAPI/Controller/1.0/All?TableName=Factory',
  allObjectType: '/LTSAPI/Controller/1.0/All?TableName=ObjectType',
  allMaterial: '/LTSAPI/Controller/1.0/All?TableName=Material',
  allProduct: '/LTSAPI/Controller/1.0/All?TableName=Product',
  allWorker: '/LTSAPI/Controller/1.0/All?TableName=Worker',
  allTool: '/LTSAPI/Controller/1.0/All?TableName=Tool',
  allJob: '/LTSAPI/Controller/1.0/All?TableName=Job',
  allJobEquipment: '/LTSAPI/Controller/1.0/All?TableName=JobEquipment',
  allJobWorker: '/LTSAPI/Controller/1.0/All?TableName=JobWorker',
  allComponent: '/LTSAPI/Controller/1.0/All?TableName=Component',
  allMachineHistory: '/LTSAPI/Controller/1.0/All?TableName=EquipmentHistory',
  allProcessState: '/LTSAPI/Controller/1.0/All?TableName=ProcessState',
  allProgressStatus: '/LTSAPI/Controller/1.0/All?TableName=ProgressStatus',
  allMachine: '/LTSAPI/Controller/1.0/All?TableName=Machine',
  allTag: '/LTSAPI/Controller/1.0/All?TableName=Tag',
  odbcSetup: '/LTSAPI/Controller/1.0/ODBC',
  odbcJobs: '/LTSAPI/Controller/1.0/ODBC/Jobs',
  odbcComponents: '/LTSAPI/Controller/1.0/ODBC/Components',
  odbcClient: '/LTSAPI/Controller/1.0/ODBC/Client',
  odbcWorkers: '/LTSAPI/Controller/1.0/ODBC/Workers',
  componentStatus: '/LTSAPI/Controller/1.0/ComponentStatus?Component_IDS=',
  jobStatus: '/LTSAPI/Controller/1.0/JobStatus',
  connectionCheck: '/LTSAPI/Controller/1.0/odbc/connectionCheck',
  getErrors: '/LTSAPI/Controller/1.0/LogService/allErrors',
  getUsers: '/LTSAPI/Controller/1.0/users',
  user: '/LTSAPI/Controller/1.0/user',
  updateUserLayout: '/LTSAPI/Controller/1.0/updateUserLayout',
  updateGraphKeys: '/LTSAPI/Controller/1.0/updateGraphKeys',
  getGraphKeys: '/LTSAPI/Controller/1.0/getGraphKeys',
  featureLimits: '/LTSAPI/Controller/1.0/FeatureLimits',
  adminTypes: '/LTSAPI/Controller/1.0/adminTypes',
  validLicenses: '/LTSAPI/Controller/1.0/licensepool',
  Jobs: '/LTSAPI/Controller/1.0/Jobs?Job_IDS=',
  machine: '/LTSAPI/Controller/1.0/Area',
  notificationSettings: '/LTSAPI/Controller/1.0/UserNotifications',
  rawdata: '/LTSAPI/Controller/1.0/rawdata',
  markReadAlert: '/LTSAPI/Controller/1.0/markReadAlert',
  updateUserRole: '/LTSAPI/Controller/1.0/updateUserRole',
  getRoles: '/LTSAPI/Controller/1.0/allRoles',
  deleteUserRole: '/LTSAPI/Controller/1.0/deleteUserRole',
  updateUserGroup: '/LTSAPI/Controller/1.0/updateUserGroup',
  getGroups: '/LTSAPI/Controller/1.0/allGroups',
  deleteUserGroup: '/LTSAPI/Controller/1.0/deleteUserGroup',
  getRadiusTime: '/LTSAPI/controller/1.0/getRadiusTime',
  getTrackingGroups: '/LTSAPI/controller/1.0/allTrackingGroups',
  updateTrackingGroup: '/LTSAPI/controller/1.0/updateTrackingGroup',
  deleteTrackingGroup: '/LTSAPI/controller/1.0/deleteTrackingGroup',
  getAssetActivity: '/LTSAPI/controller/1.0/getAssetActivityDaily',
  getAssetRadiusTimeMachines: '/LTSAPI/controller/1.0/MachineTime',
  getAreaRadiusTime: '/LTSAPI/controller/1.0/AreaTime',
  odbcDelete: '/LTSAPI/Controller/1.0/ODBC/deleteODBC',
  serverSentEvents: '/LTSAPI/Controller/1.0/serverSentEvents',
  liveTrackingData: '/LTSAPI/Controller/1.0/liveTrackingData',
  // import mapping
  getImportMappingRequirements: '/LTSAPI/controller/1.0/importMappingRequirements',
  getImportMappingsIdGroupMap: '/LTSAPI/controller/1.0/importMappingIdGroupMap',
  getImportMappings: '/LTSAPI/controller/1.0/importMapping',
  postImportMappings: '/LTSAPI/controller/1.0/importMapping',
  getImportMappingsExcel: '/LTSAPI/controller/1.0/importMappingExcel',
  postImportMappingsExcel: '/LTSAPI/controller/1.0/importMappingExcel',
  getCustomerDBScan: '/LTSAPI/controller/1.0/customerDBScan',
  postImportExcelFile: '/LTSAPI/controller/1.0/importExcelFile',
  //////// aps
  getHelpAPS: '/LTSAPI/Controller/1.0/aps/help',
  postChangeDurationAPS: '/LTSAPI/Controller/1.0/aps/changeDuration',
  postRefreshAPS: '/LTSAPI/Controller/1.0/aps/refreshAPS',
  getApsLastRefreshTime: '/LTSAPI/Controller/1.0/aps/lastRefreshTime',
  getResourceDelay: '/LTSAPI/Controller/1.0/aps/getResourceDelay',
  // internal aps
  getResourcesAPS: '/LTSAPI/Controller/1.0/aps/resources',
  getResourceUtilsAPS: '/LTSAPI/Controller/1.0/aps/resourceUtils',
  getSecondaryUtilsAPS: '/LTSAPI/Controller/1.0/aps/secondaryUtils',
  getOrdersAPS: '/LTSAPI/Controller/1.0/aps/orders',
  getOrderStatusAPS: '/LTSAPI/Controller/1.0/aps/orderStatus',
  getOrderByIdAPS: '/LTSAPI/Controller/1.0/aps/order?ID=',
  getOrderNumbersAPS: '/LTSAPI/Controller/1.0/aps/orderNumbers',
  getResourceNamesAPS: '/LTSAPI/Controller/1.0/aps/resourceNames',
  getSecondaryConstraintNamesAPS: '/LTSAPI/Controller/1.0/aps/secondaryConstraintNames',
  getResourceUtilByIdAPS: '/LTSAPI/Controller/1.0/aps/resourceUtil?ID=',
  getSecondaryResourceUtilByIdAPS: '/LTSAPI/Controller/1.0/aps/secondaryResourceUtil?ID=',
  getImportMappingScheduling: '/LTSAPI/Controller/1.0/aps/importMappingScheduling',
  postImportMappingScheduling: '/LTSAPI/Controller/1.0/aps/importMappingScheduling',
  getCustomerDbScanScheduling: '/LTSAPI/Controller/1.0/aps/customerDbScanScheduling',
  getUseApsDefaultImportMapping: '/LTSAPI/Controller/1.0/aps/useApsDefaultImportMapping',
  postUseApsDefaultImportMapping: '/LTSAPI/Controller/1.0/aps/useApsDefaultImportMapping',
  getJobComponentConfigOption: '/LTSAPI/Controller/1.0/aps/jobComponentConfigOption',
  postJobComponentConfigOption: '/LTSAPI/Controller/1.0/aps/jobComponentConfigOption',
  postResourceHistoricalDelay: '/LTSAPI/Controller/1.0/aps/resourceHistoryDelay',
  getOfflineHistory: '/LTSAPI/Controller/1.0/aps/offlineHistory',
  // external aps, for testing, do not delete
  getResourcesExternalAPS: '/LTSAPI/Controller/1.0/aps/resourcesExternal',
  getResourceUtilsExternalAPS: '/LTSAPI/Controller/1.0/aps/resourceUtilsExternal',
  getSecondaryUtilsExternalAPS: '/LTSAPI/Controller/1.0/aps/secondaryUtilsExternal',
  getOrdersExternalAPS: '/LTSAPI/Controller/1.0/aps/ordersExternal',
  addAndroidDevice: '/LTSAPI/Controller/1.0/addAndroidDevice'
};

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { CLIENT } = config;

  const webAuth = useWebAuth({ CLIENT, location, navigate, ROUTES, CONTROLLER_URLS });
  const teamsAuth = useTeamsAuth({ CLIENT, location, navigate, ROUTES, CONTROLLER_URLS });
  const androidAuth = useAndroidAuth({ CLIENT, location, navigate, ROUTES, CONTROLLER_URLS });

  let auth = webAuth;
  if (CLIENT.TEAMS_APP) auth = teamsAuth;
  if (CLIENT.ANDROID_APP) auth = androidAuth;
  const {
    setForceSignOut,
    promptForceSignOut,
    authenticated,
    setAuthenticated,
    appReady: appReady_auth,
    instance,
    accounts,
    accessToken,
    accessTokenEmail,
    // initialLoad,
    properUser,
    setNeedRedirectLogin,
    setActiveLogOut,
    setForceUserRefresh,
    URLs,
    // isAuthenticated,
    // login,
    // graphReload,
    // teamsUserCredential,
    // teamsLoginError,
    // setTeamsLoginError,
    // showSpinner,
    // setShowSpinner,
    // msToken,
    showBars,
    startPressedTagAlerts,
    tabConfigPage,
    loginPageProps,
    getToken
  } = auth;

  const [applicationSettings, setApplicationSettings] = useState(null); //App/global settings (like individual aka worker view mode)
  const appReady = !!applicationSettings && appReady_auth;

  const { userNotifications } = useServerSentEvents({ URLs, accessToken, user: properUser, CLIENT });

  //Was the bell clicked, and notification area needs to be expanded
  const [notificationsExpand, setNotificationsExpand] = useState(false);
  const [fakeNotif, setFakeNotif] = useState(false); //Whether the fake notifaction was activated
  const [activeNotif, setActiveNotif] = useState(false); //Is there an active notifiaction that hasn't been read (used to display red dot on bell)
  const [factoryDetailKey, setFactoryDetailKey] = useState(1); // used to unmount/remount component to toggle a refresh after changing tag assignments
  const fetchedSharedConfig = useRef(false);

  const [floorImage, setFloorImage] = useState({
    // length: 141.12,
    // width: 73.6,
    length: 91.873,
    width: 62.67,
    image: 'placeholder'
  });

  // Legacy which will be removed in the future
  // error codes should be handled within their respective files NOTE
  const [errorCodes, setErrorCodes] = useState({
    showError: false,
    errorCodes: []
  });

  const [refreshSidebar, setRefreshSidebar] = useState(true);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  // TODO: should memoize this
  const mediaQueries = {
    isBigScreen: isBigScreen,
    isDesktopOrLaptop: isDesktopOrLaptop,
    isTabletOrMobile: isTabletOrMobile,
    isPortrait: isPortrait,
    isRetina: isRetina
  };

  /*********************************
   *  uncomment for mobile app
   **/
  // const backButton = useBackButton({
  //   pathname: location.pathname,
  //   notificationsExpand: notificationsExpand,
  //   setNotificationsExpand: setNotificationsExpand
  // });

  const backButton = null;

  const liveTrackingData = useLiveTrackingData({ accessToken, controllerURLs: URLs, user: properUser, getToken });

  const liveMapContainerHeight = getWindowSize();

  function getWindowSize() {
    const { innerWidth /*, innerHeight */ } = window;
    return (floorImage.width / floorImage.length) * innerWidth;
  }

  // Grab application settings
  useEffect(() => {
    if (accessToken && URLs?.getApplicationSettings) {
      const getAppSettings = async () => {
        let data = await helper.GET(accessToken, URLs.getApplicationSettings);
        setApplicationSettings(data.data);
      };
      getAppSettings();
    }
  }, [accessToken, URLs]);

  useEffect(() => {
    /**
     * WHEN UPDATING 3D ASSETS UPDATE INDEXED_DB_VERSION NUMBER IN sharedConfig.js
     * this will reset the browser cache
     *
     * TODO: should be in babylon.js but for some reason it messed up the loading
     */
    const fetchSharedConfig = async () => {
      fetchedSharedConfig.current = true;
      // const debugDomain = 'http://localhost:80';
      const sharedConfig = await helper.GET('access token not needed here', `${properUser.domain}/sharedConfig`);
      const serverDbVersion = sharedConfig.indexed_db_version;
      const browserDBVersion = Number(localStorage.getItem('indexedDbVersion'));
      if (serverDbVersion !== browserDBVersion) {
        console.log('deleting cache for babylon assets');
        indexedDB.deleteDatabase('babylonjs'); // fyi to await this you need to wrap in a promise
        localStorage.setItem('indexedDbVersion', serverDbVersion);
      }
    };

    if (!properUser || fetchedSharedConfig.current) return;
    fetchSharedConfig();
  }, [properUser]);

  // set up floor image
  useEffect(() => {
    if (accessToken && URLs?.image) {
      setUpImage(accessToken, URLs.image, setFloorImage);
    }
  }, [accessToken, URLs]);

  useEffect(() => {
    if (floorImage) if (floorImage.image !== 'placeholder') new Image().src = floorImage.image;
  }, [floorImage]);

  //This just gets the image from the quuppa tracker, and sets the dimensions of the area according to the project
  const setUpImage = async function (token, getImageUrl, setImage) {
    let res = await helper.GET(token, getImageUrl);

    // let requestOptions = {
    //   method: 'GET',
    //   headers: {
    //     Authorization: token,
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json',
    //     // "Access-Control-Allow-Origin": "*",
    //   },
    // };

    // console.log(result)

    //If there was no image then fall back to a saved image, however, this really is a bandaid and is not scalable nor should be
    // NOTE
    if (!res || res.errorCode) {
      // let image = { length: 141.12, width: 73.6, image: 'placeholder' }; // NOTE temp override
      let image = {
        length: 91.873,
        width: 62.67,
        image: 'placeholder'
      }; // NOTE temp override
      // let image = { length: 10, width: 9, image: `/shop_floor.png` };

      // console.log(image);
      // console.log(shopFloor);
      setImage(image);

      //Use below for proper stuff
      //for generating area dimensions based on the project data instead of hardcoded values and image stuffs
      // let zone = await getData(
      //   token,
      //   getZoneUrl,
      //   null,
      //   errorCodes,
      //   setErrorCodes,
      //   true
      // );
      // zone = zone.zone.split("|");
      // setImageFromZone(zone, setImage);
    } else {
      // console.log("image:");
      // console.log(result);

      setImage(res);
    }
  };

  return (
    <AppContext.Provider
      value={{
        accessToken,
        accessTokenEmail,
        user: properUser,
        controllerURLs: URLs,
        mediaQueries,
        refreshSidebar,
        setRefreshSidebar,
        backButton,
        CLIENT,
        setForceSignOut,
        promptForceSignOut,
        applicationSettings,
        setApplicationSettings
      }}
    >
      <div
        className="App"
        onBlur={() => {
          console.log('window blurred');
        }}
      >
        {/*Suspense is used with lazy loading, until the lazy loaded components are loaded in, it displays the 'fallback' content */}
        <Suspense fallback={<div>Loading...</div>}>
          {/* Below are all the appropriate components being loaded in with the associated information/state that they need to function */}
          {/* Authenticated/Unathenticated templates are microsoft auth library. Every page checks that as a minimum */}
          {/* a valid microsoft account is logged in, otherwise unauthorised redirects to login*/}
          {/* if a valid microsoft account is used, but it is not authorised with our services, redirects to 401 and signs them out*/}

          {appReady && (
            <Notification
              notificationsExpand={notificationsExpand}
              setNotificationsExpand={setNotificationsExpand}
              setActiveNotif={setActiveNotif}
              fakeNotif={fakeNotif}
              setFakeNotif={setFakeNotif}
              userNotifications={userNotifications}
            />
          )}
          {showBars && (
            <Topbar
              account={accounts[0]}
              instance={instance}
              activeNotif={activeNotif}
              fakeNotif={fakeNotif}
              setFakeNotif={setFakeNotif}
              notificationsExpand={notificationsExpand}
              setNotificationsExpand={setNotificationsExpand}
            />
          )}
          <div
            className={
              mediaQueries.isTabletOrMobile && location.pathname !== '/login' ? 'appHolderMobile' : 'appHolder'
            }
            style={location.pathname === '/dashboard' ? { overflowY: 'hidden' } : {}}
          >
            {showBars && <Sidebar isAuthenticated={true} apiAuthentication={true} instance={instance} />}
            {startPressedTagAlerts && (
              <PressedTagAlert
                apiAuthentication={authenticated}
                refreshFactoryDetail={() => {
                  setFactoryDetailKey(factoryDetailKey + 1);
                  liveTrackingData.refreshCompStatus();
                }}
                queryPressedTags={liveTrackingData.data.queryPressed}
              />
            )}
            <Routes>
              <Route
                path="/"
                exact
                element={
                  appReady ? (
                    <Landing
                      floorImage={floorImage}
                      instance={instance}
                      errorCodes={errorCodes}
                      setErrorCodes={setErrorCodes}
                      liveMapContainerHeight={liveMapContainerHeight}
                      liveTrackingData={liveTrackingData}
                    />
                  ) : promptForceSignOut ? (
                    <Login props={loginPageProps} />
                  ) : (
                    <LoadingPage />
                  )
                }
              ></Route>
              {tabConfigPage && <Route path="/index.html" exact element={tabConfigPage} />}
              <Route path="/login" exact element={<Login props={loginPageProps} />} />
              <Route
                path="/unauthorised"
                exact
                element={<UnauthorisedLanding setAuthenticated={setAuthenticated} />}
              ></Route>
              <Route
                path="/areaGenerator"
                exact
                element={appReady ? <AreaGeneratorLanding floorImage={floorImage} /> : <LoadingPage />}
              ></Route>
              <Route
                path="/detail"
                exact
                element={
                  appReady ? (
                    <FactoryDetail
                      floorImage={floorImage}
                      instance={instance}
                      errorCodes={errorCodes}
                      setErrorCodes={setErrorCodes}
                      liveMapContainerHeight={liveMapContainerHeight}
                      key={factoryDetailKey}
                      factoryDetailKey={factoryDetailKey}
                      liveTrackingData={liveTrackingData}
                    />
                  ) : promptForceSignOut ? (
                    <Login props={loginPageProps} />
                  ) : (
                    <LoadingPage />
                  )
                }
              ></Route>
              <Route path="/dashboard" exact element={appReady ? <DashboardLanding /> : <LoadingPage />}></Route>
              <Route
                path="/insight"
                exact
                element={
                  appReady ? (
                    <>
                      <InsightContextProvider>
                        <InsightsLanding />
                      </InsightContextProvider>
                    </>
                  ) : (
                    <LoadingPage />
                  )
                }
              ></Route>
              <Route
                path="/error"
                exact
                element={appReady ? <ErrorLanding errorCodes={errorCodes} /> : <LoadingPage />}
              ></Route>
              <Route path="/aps" exact element={appReady ? <ApsLanding /> : <LoadingPage />}></Route>
              <Route
                path="/settings"
                exact
                element={
                  appReady ? (
                    <SettingsLanding
                      setForceUserRefresh={setForceUserRefresh}
                      floorImage={floorImage}
                      errorCodes={errorCodes}
                      instance={instance}
                      setRedirect={setNeedRedirectLogin}
                      setActiveLogOut={setActiveLogOut}
                      setAuthenticated={setAuthenticated}
                    />
                  ) : (
                    <LoadingPage />
                  )
                }
              ></Route>
              <Route path="*" element={<div>404 Page Not Found</div>}></Route>
            </Routes>
          </div>
        </Suspense>
      </div>
    </AppContext.Provider>
  );
}

export default App;

function LoadingPage() {
  return <div>Loading...</div>;
}
