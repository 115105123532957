import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import helper from '../util/helper';

const FETCH_INTERVAL = helper.timeInMS.second;
const COMPONENT_STATUS_REFRESH_INTERVAL = 30;

export default function useLiveTrackingData({ accessToken, controllerURLs, user, getToken }) {
  const location = useLocation();
  const [data, setData] = useState({});
  const [pauseFetch, setPauseFetch] = useState(false);
  const compIds = useRef([]);
  const fetchDataCounter = useRef(0); // increments after each fetch

  useEffect(()=>{
    fetchDataCounter.current = 0;
  }, [location])

  useEffect(() => {
    function setCompIds(tags) {
      if (!tags) return;

      let ids = [];
      let jobBlock = false;
      let enabledJobs = {};
      let componentBlock = false;
      let enabledComponents = {};

      if (user.featureLimits.jobBlock) {
        jobBlock = true;
        user.featureLimits.enabledJobs.forEach((job) => {
          enabledJobs[job] = true;
        });

        if (user.featureLimits.enabledJobs.length === 0) {
          enabledJobs.allEnabled = true;
        } else {
          enabledJobs.allEnabled = false;
        }
      }
      if (user.featureLimits.componentBlock) {
        componentBlock = true;

        user.featureLimits.enabledComponents.forEach((component) => {
          enabledComponents[component] = true;
        });

        if (user.featureLimits.enabledComponents) {
          if (user.featureLimits?.enabledComponents?.length === 0) {
            enabledComponents.allEnabled = true;
          }
        } else {
          enabledComponents.allEnabled = false;
        }
      }

      if (!user.guest) {
        // let defaultComponents = {};

        //filter out for component IDS
        for (let i = 0; i < tags.length; i++) {
          if (!tags[i].End_Time || tags[i].End_Time === 'null')
            if (tags[i].ObjectType_ID === 3) {
              if (jobBlock) {
                if (!enabledJobs[tags[i].Job_ID] && !enabledJobs.allEnabled) {
                  continue;
                }
              } else {
                continue;
              }
              if (componentBlock) {
                if (!enabledComponents[tags[i].Component_ID] && !enabledComponents.allEnabled) {
                  continue;
                }
              }
              ids.push(tags[i].Component_ID);
            }
        }
      } else {
        let idMap = {};
        for (let i = 0; i < user.Jobs.length; i++) {
          idMap[user.Jobs[i]] = true;
        }

        for (let i = 0; i < tags.length; i++) {
          if (!tags[i].End_Time || tags[i].End_Time === 'null')
            if (tags[i].ObjectType_ID === 3) {
              if (tags[i].Job_ID in idMap) {
                ids.push(tags[i].Component_ID);
              }
            }
        }
      }

      compIds.current = ids;
    }

    function getUrl() {
      switch (location.pathname) {
        case '/':
        case '/detail':
          // after first call we collect the component ids and then we can set param for Component_IDS
          // only calculating component status every 4th call
          let componentIds = compIds.current;
          if (compIds.current.length) {
            if (fetchDataCounter.current !== 0) {
              componentIds = [];
            }

            if (fetchDataCounter.current > COMPONENT_STATUS_REFRESH_INTERVAL) {
              fetchDataCounter.current = 0;
            } else {
              fetchDataCounter.current = fetchDataCounter.current + 1;
            }
          }

          const querySpecific = user.guest ? true : false; // will call /querySpecific for tags on backend
          return `${controllerURLs.liveTrackingData}?Component_IDS=${componentIds}&querySpecific=${querySpecific}`;
        case '/insight':
        case '/aps':
        case '/settings':
          return `${controllerURLs.liveTrackingData}?skipQueryAll=true&skipProcessState=true&skipComponentStatus=true`;
        default:
          console.log('404 page');
          return false;
      }
    }

    async function getData() {
      if (!controllerURLs || !accessToken || !user || pauseFetch) return;

      try {
        const url = getUrl();
        if (!url) return;

        let data = await helper.GET(accessToken, url);
        if (data?.error) throw new Error('error fetching /liveTrackingData');
        if (data?.authorised === false) {
          console.log('not authorised, stopping liveTrackingData');
          clearInterval(interval);
          if (getToken) await getToken(); // remove if once apk and teams app updated
          return;
        }
        if (data.queryAll) data.queryAll = data.queryAll.result.rowset.rows;

        setData(data);
        setCompIds(data.queryAll);
      } catch (err) {
        console.error(err);
      }
    }

    let interval = setInterval(() => {
      getData();
    }, FETCH_INTERVAL);

    // return () => clearInterval(interval);
    return () => clearTimeout(interval);
  }, [accessToken, controllerURLs, pauseFetch, user, location.pathname, getToken]);

  function refreshCompStatus() {
    fetchDataCounter.current = 0;
  }

  return {
    data,
    setPauseFetch,
    refreshCompStatus
  };
}
