/**
 *
 * @param {*} user
 * @returns a new featureLimits object
 *
 * We have roles included in the user object, but they are not applied to limit the users access.
 * So this hook will apply the roles to the user.
 */

export default function applyUserRoles(user) {
  if (!user) return;
  if (!user.userSettings?.roles) {
    console.log('user has no roles');
    return user.featureLimits;
  }

  const currFeatureLimits = { ...user.featureLimits };
  let newFeatureLimits = {};
  let roles = user.userSettings.roles;

  // if groups are assigned to roles, adding to roles array
  if (Object.keys(user.userSettings.groups).length > 0) {
    Object.values(user.userSettings.groups).forEach((roleLimits) => roles.push(roleLimits));
  }

  const enabledMap = {
    workerBlock: 'enabledWorkers',
    assetBlock: 'enabledAssets',
    componentBlock: 'enabledComponents',
    machineStatus: 'enabledMachines',
    jobBlock: 'enabledJobs'
  };

  for (const feature of Object.keys(currFeatureLimits)) {
    // if feature already disabled we skip
    if (!currFeatureLimits[feature] || currFeatureLimits === -1) {
      newFeatureLimits[feature] = currFeatureLimits[feature];
      continue;
    }

    // else we go thru roles to find any conflicts
    let enableFeature = currFeatureLimits[feature];
    let isArray = false;
    // let skip = false;

    // when we get an array (ex. enabledWorker)
    // just combining all array vals from roles
    if (feature.includes('enabled')) {
      enableFeature = new Set(enableFeature);
      isArray = true;
    }

    // to handle case where we have enabled objects and all objects enabled
    let emptyArray = false;

    for (const role of roles) {
      //console.log("role: ", JSON.stringify(role));
      //console.log("feature: ", feature);

      // featurelimits uses the plural version (ex. enabledWorkers)
      const roleFeature = role[isArray ? feature.slice(0, -1) : feature];

      if (!roleFeature) emptyArray = true;

      // when we get an array (ex. enabledWorkers)
      // currently we just add values from both arrays (so no conflicts)
      if (roleFeature && isArray) {
        //console.log("roleFeature: ", roleFeature);
        if (roleFeature.length > 0) {
          // eslint-disable-next-line no-loop-func
          roleFeature.forEach((item) => enableFeature.add(item));
        } else emptyArray = true;
      } else if (roleFeature === -3) continue;
      else if (roleFeature === 0) {
        enableFeature = false;
        break;
      } else if (roleFeature === 1) {
        enableFeature = true;
      }
    }

    if (isArray && !emptyArray) {
      newFeatureLimits[feature] = [...enableFeature];
    } else if (isArray && emptyArray) {
      newFeatureLimits[feature] = [];
    } else {
      newFeatureLimits[feature] = enableFeature;
    }
  }

  // just clearing out arrays if the feature is disabled
  Object.keys(enabledMap).forEach((key) => {
    if (!newFeatureLimits[key]) {
      newFeatureLimits[enabledMap[key]] = [];
    }
  });

  return newFeatureLimits;
}
