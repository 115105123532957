
const helper = {
  PARSE_RESPONSE: function (resp) {
    if (resp?.data?.result?.rowset?.rows) {
      return resp?.data?.result?.rowset?.rows;
    }
    if (resp?.result?.rowset?.rows) {
      return resp?.result?.rowset?.rows;
    }
    return [];
  },

  /**
   * Helper methods for http requests
   **/
  GET: async function (accessToken, url, debug, extraParams) {
    let requestOptions = {
      method: 'GET',
      headers: extraParams?.headers ? extraParams.headers : {
        Authorization: accessToken,
        'Content-Type': 'application/json',
        Accept: 'application/json'
        // "Access-Control-Allow-Origin": "*",
      }
    };

    let res;

    try {
      if (debug) {
        console.log('-----');
        console.log(requestOptions);
        console.log(url);
        console.log('-----');
      }

      const data = await fetch(url, requestOptions).catch(function (err) {
        console.log(err);
      });
      res = await data.json();
    } catch (err) {
      console.log(err);
      console.log(accessToken);
      console.log(`error on GET at ${url}`);
    }
    return res;
  },
  // NOTE POST NEEDS TO BE CHECKED WITH ALL THE ROUTES!
  POST: async function (accessToken, url, body, HEADERS) {
    let headers = !!HEADERS
      ? {
        ...HEADERS,
        Authorization: accessToken,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
      : {
        Authorization: accessToken,
        'Content-Type': 'application/json',
        Accept: 'application/json'
        // "Access-Control-Allow-Origin": "*",
      };

    let requestOptions = {
      method: 'POST',
      headers: headers
    };

    requestOptions['body'] = JSON.stringify(body);

    let data;

    try {
      data = await fetch(url, requestOptions).catch(function (err) {
        console.log(err);
      });
    } catch (err) {
      console.log(err);
      console.log(accessToken);
      console.log(`error on POST at ${url}`);
    }
    // let res = await data.json();
    return data;
  },
  // NOTE PUT NEEDS TO BE CHECKED WITH ALL THE ROUTES!
  PUT: async function (accessToken, url, body, headers) {
    if (!headers) headers = {};
    let requestOptions = {
      method: 'PUT',
      headers: {
        ...headers,
        Authorization: accessToken,
        'Content-Type': 'application/json',
        Accept: 'application/json'
        // "Access-Control-Allow-Origin": "*",
      }
    };

    requestOptions['body'] = JSON.stringify(body);

    let data;

    try {
      data = await fetch(url, requestOptions).catch(function (err) {
        console.log(err);
      });
    } catch (err) {
      console.log(err);
      console.log(accessToken);
      console.log(`error on PUT at ${url}`);
    }
    // let res = await data.json();
    return data;
  },
  // NOTE DELETE NEEDS TO BE CHECKED WITH ALL THE ROUTES!
  DELETE: async function (accessToken, url, body) {
    let requestOptions = {
      method: 'DELETE',
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json',
        Accept: 'application/json'
        // "Access-Control-Allow-Origin": "*",
      }
    };

    requestOptions['body'] = JSON.stringify(body);

    let data;
    try {
      data = await fetch(url, requestOptions).catch(function (err) {
        console.log(err);
      });
    } catch (err) {
      console.log(err);
      console.log(accessToken);
      console.log(`error on PUT at ${url}`);
    }
    // let res = await data.json();
    return data;
  },
  // returns the seconds given as a sentence (ie. 1 day ago)
  secondsToTextTime: function (seconds) {
    seconds = Math.round(seconds);

    let notifTime = 'Error';

    if (seconds < 1) {
      notifTime = 'A second ago';
    } else if (seconds === 1) {
      notifTime = '1 second';
    } else if (seconds < 60) {
      notifTime = `${Math.round(seconds)} seconds`;
    } else if (seconds <= 2 * 60) {
      notifTime = `1 minute`;
    } else if (seconds < 60 * 60) {
      notifTime = `${Math.round(seconds / 60)} minutes`;
    } else if (seconds <= 2 * 60 * 60) {
      notifTime = `1 hour`;
    } else if (seconds < 60 * 60 * 24) {
      notifTime = `${Math.round(seconds / (60 * 60))} hours`;
    } else if (seconds <= 2 * 60 * 60 * 24) {
      notifTime = `1 day`;
    } else if (seconds < 60 * 60 * 24 * 7) {
      notifTime = `${Math.round(seconds / (60 * 60 * 24))} days`;
    } else if (seconds <= 2 * 60 * 60 * 24 * 7) {
      notifTime = `1 week`;
    } else if (seconds < 60 * 60 * 24 * 7 * 4) {
      notifTime = `${Math.round(seconds / (60 * 60 * 24 * 7))} weeks`;
    } else if (seconds <= 2 * 60 * 60 * 24 * 7 * 4) {
      notifTime = `1 month`;
    } else {
      notifTime = `${Math.round(seconds / (60 * 60 * 24 * 7 * 4))} months`;
    }
    return notifTime;
  },
  //Function to get the current time exactly one day/week/month ago in ISO string format
  setOneDayAgo: function () {
    let temp = new Date();
    temp.setDate(temp.getDate() - 1);
    return temp.toISOString();
  },
  setOneWeekAgo: function () {
    let temp = new Date();
    temp.setDate(temp.getDate() - 7);
    return temp.toISOString();
  },
  setOneMonthAgo: function () {
    let temp = new Date();
    temp.setMonth(temp.getMonth() - 1);
    return temp.toISOString();
  },

  msToHours: function (ms) {
    return Number(ms / 1000 / 60 / 60);
  },

  sToHours: function (seconds) {
    return Number(seconds / 60 / 60);
  },

  getLog10: function (number, base) {
    base = base ? base : 10;
    if (base === 1) return number;
    return Math.log(number) / Math.log(base);
  },
  getRectangle: function (points, dot, oppDot, machineForce) {
    // console.log('inside helper')
    // console.log(dot);

    let maxX = 0;
    let minX = 99999;
    let maxY = 0;
    let minY = 99999;
    // console.log(points);
    const Z = points[0].z;

    let selectedDot = !!dot ? dot.dot : null;
    let index = !!dot ? dot.index : null;

    let internalPoints = [...points];
    // console.log('inside helper')
    // console.log(selectedDot)
    // console.log(oppDot)
    // console.log(`dot:`)
    // console.log(dot);
    // console.log(`index: ${index}`)
    // console.log('all points')
    // console.log(points)
    if (selectedDot && !oppDot) {
      // console.log('inside if')
      let otherDot = index + 2;
      otherDot = otherDot % 4;
      internalPoints = [selectedDot, points[otherDot]];
      if (internalPoints[0].y === internalPoints[1].y) {
        let tempIndex = index + 1;
        tempIndex = tempIndex % 4;
        internalPoints.push(points[tempIndex]);
      }
    } else if (selectedDot) {
      // console.log('inside else')
      internalPoints = [selectedDot, oppDot.dot];

      if (selectedDot.x - oppDot.dot.x < 0) {
        minX = selectedDot.x;
        maxX = oppDot.dot.x;
      } else {
        minX = oppDot.dot.x;
        maxX = selectedDot.x;
      }

      if (selectedDot.y - oppDot.dot.y < 0) {
        minY = selectedDot.y;
        maxY = oppDot.dot.y;
      } else {
        minY = oppDot.dot.y;
        maxY = selectedDot.y;
      }

      let rectangle = [
        {
          Tag_Code: 0,
          Location_X: minX,
          x: minX,
          Location_Y: minY,
          y: minY,
          Location_Z: Z,
          z: Z
        },
        {
          Tag_Code: 1,
          Location_X: maxX,
          x: maxX,
          Location_Y: minY,
          y: minY,
          Location_Z: Z,
          z: Z
        },
        {
          Tag_Code: 2,
          Location_X: maxX,
          x: maxX,
          Location_Y: maxY,
          y: maxY,
          Location_Z: Z,
          z: Z
        },
        {
          Tag_Code: 3,
          Location_X: minX,
          x: minX,
          Location_Y: maxY,
          y: maxY,
          Location_Z: Z,
          z: Z
        }
      ];

      let index = -1;
      let oldIndex = -1;

      for (let i = 0; i < rectangle.length; i++) {
        let element = rectangle[i];
        if (element.x === selectedDot.x && element.y === selectedDot.y) {
          // console.log('found a spot at: ', i)
          index = i;
        } else if (element.x === oppDot.dot.x && element.y === oppDot.dot.y) {
          // console.log('found an old spot at: ', i)
          oldIndex = i;
        } else {
          // console.log(element.x, selectedDot.x, element.x === selectedDot.x)
          // console.log(element.y, selectedDot.y, element.y === selectedDot.y)
        }
      }

      return {
        index: index,
        oldIndex: oldIndex,
        maxX: maxX,
        minX: minX,
        maxY: maxY,
        minY: minY,
        rectangle: rectangle
      };
    }

    // console.log('dot')
    // console.log(selectedDot)
    // console.log('points')
    // console.log(points);
    // console.log('internal points:')
    // console.log(internalPoints);

    for (let i = 0; i < internalPoints.length; i++) {
      let curX = internalPoints[i].x ? internalPoints[i].x : internalPoints[i].Location_X;
      let curY = internalPoints[i].y ? internalPoints[i].y : internalPoints[i].Location_Y;
      if (curX > maxX) maxX = curX;
      if (curX < minX) minX = curX;
      if (curY > maxY) maxY = curY;
      if (curY < minY) minY = curY;
    }

    // If a dot was provided, we need to check if it any of its x/y should be
    //forcefuly set as the min/max depending on which one is closest
    if (selectedDot) {
      let xMinDis = Math.abs(selectedDot.x - minX);
      let xMaxDis = Math.abs(selectedDot.x - maxX);
      if (xMinDis < xMaxDis) {
        // console.log('taking minx')
        minX = selectedDot.x;
      } else {
        // console.log('taking mAXx')
        maxX = selectedDot.x;
      }

      let yMinDis = Math.abs(selectedDot.y - minY);
      let yMaxDis = Math.abs(selectedDot.y - maxY);
      if (yMinDis < yMaxDis) {
        // console.log('taking minY')
        minY = selectedDot.y;
      } else {
        // console.log('taking mAXX YY')
        maxY = selectedDot.y;
      }
    }

    let rectangle = [
      {
        Tag_Code: 0,
        Location_X: minX,
        x: minX,
        Location_Y: minY,
        y: minY,
        Location_Z: Z,
        z: Z
      },
      {
        Tag_Code: 1,
        Location_X: maxX,
        x: maxX,
        Location_Y: minY,
        y: minY,
        Location_Z: Z,
        z: Z
      },
      {
        Tag_Code: 2,
        Location_X: maxX,
        x: maxX,
        Location_Y: maxY,
        y: maxY,
        Location_Z: Z,
        z: Z
      },
      {
        Tag_Code: 3,
        Location_X: minX,
        x: minX,
        Location_Y: maxY,
        y: maxY,
        Location_Z: Z,
        z: Z
      }
    ];
    // console.log('returning big rectangle')
    // console.log(rectangle);
    return {
      maxX: maxX,
      minX: minX,
      maxY: maxY,
      minY: minY,
      rectangle: rectangle
    };
  },
  hslToRgb: function (h, s, l) {
    //Credit: https://jsfiddle.net/Lamik/reuk63ay
    let a = s * Math.min(l, 1 - l);
    let f = (n, k = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return [f(0), f(8), f(4)];
  },
  customAngle2: function (A, B, C) {
    // let result = Math.atan2(A.y - B.y, A.x - B.x) - Math.atan2(C.y - B.y, C.x - B.x);
    let result = Math.atan2(B.y - A.y, B.x - A.x) - Math.atan2(C.y - A.y, C.x - A.x);
    return result;
  },
  // capitalize first letter of each word
  standardCase: function (string) {
    if (!string) return string;

    let temp = string.split(' ');
    string = '';

    temp.forEach((value, index) => {
      if (value[0]) {
        value = value.toLowerCase();
        value = value[0].toUpperCase() + value.substring(1, value.length);
        string += value + ' ';
      }
    });
    string = string.trim();

    return string;
  },
  findCenterPoint: function (points) {
    let centerX = 0;
    let centerY = 0;

    let xMax = 0;
    let xMin = Number.MAX_SAFE_INTEGER;
    let yMax = 0;
    let yMin = Number.MAX_SAFE_INTEGER;

    for (let i = 0; i < points.length; i++) {
      centerX += points[i].x;
      centerY += points[i].y;
      if (points[i].x < xMin) {
        xMin = points[i].x;
      }
      if (points[i].x > xMax) {
        xMax = points[i].x;
      }
      if (points[i].y < yMin) {
        yMin = points[i].y;
      }
      if (points[i].y > yMax) {
        yMax = points[i].y;
      }
    }
    centerX /= points.length;
    centerY /= points.length;
    // let midY = (yMax - yMin) / 2;
    // let midX = (xMax - xMin) / 2;

    return {
      centerX: centerX,
      centerY: centerY,
      xMin: xMin,
      xMax: xMax,
      yMin: yMin,
      yMax: yMax
    };
  },
  tagToString: function (tag) {
    let string = '';

    switch (tag.ObjectType_ID) {
      case 1:
        string += `Employee: ${this.standardCase(tag.worker_name)}`;
        break;
      case 2:
        string += `Asset: ${tag.tool_name}`;
        break;
      case 3:
        string += `Job: #${tag.Job_Name} - ${tag.component_description}`;
        break;
      default:
        break;
    }
    return string;
  },
  roundTwoDecimal: function (number) {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  },
  timeInMS: {
    second: 1000,
    minute: 60000,
    hour: 3600000,
    day: 86400000,
    week: 604800000,
    month: 2419200000
  },
  genRanHex: function () {
    const randomHex = [...Array(6)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
    return `#${randomHex}`;
  },
  getColor: function (index) {
    const COLOR_LIST = [
      `#FC3400`,
      `#94E5D8`,
      `#5542F6`,
      `#FFB81C`,
      `#44A4F7`,
      `#E9739B`,
      `#20C9AC`,
      `#0066B5`,
      `#C8C2FC`,
      `#FFDFC0`,
      `#FFBEBE`,
      `#A7E0FF`
    ]
    if(index > COLOR_LIST.length - 1){
      return helper.genRanHex();
    }
    return COLOR_LIST[index];
  },

  sleep: async function (ms) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }
};
export default helper;
