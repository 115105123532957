import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import AppContext from '../../../context/AppContext';
import helper from '../../../util/helper';
import landing from '../../../assets/Left-1-1-Dashboard.svg';
import factory from '../../../assets/Left-1-2-Factory.svg';
// import error from "../assets/Left-1-3-Error code.svg";
// import dashboard from '../../../../assets/Left-1-4-Analysis.svg';
import apsLogo from '../../../assets/Left-1-4-Scheduling.svg';
import settings from '../../../assets/Left-1-5-Settings.svg';
import insightLogo from '../../../assets/Left-1-4-Insight.svg';

const TABS = [
  {name: 'home', displayName: 'Home', icon: landing, link: '/'},
  {name: 'factory', displayName: 'Details' , icon: factory, link: '/detail'},
  {name: 'insight', displayName: 'Insight', icon: insightLogo, link: '/insight'},
  {name: 'aps', displayName: 'APS Dashboard', icon: apsLogo, link: '/aps'},
  {name: 'settings', displayName: 'Settings', icon: settings, link: 'settings'}
]

export default function useSideBarLogic() {
  const { user, controllerURLs, accessToken, refreshSidebar, setRefreshSidebar } = useContext(AppContext);
  //Required react-router stuff
  const location = useLocation();

  let currentNav = 'home';

  switch (location.pathname) {
    case '/':
      currentNav = 'home';
      break;
    case '/detail':
      currentNav = 'factory';
      break;
    case '/dashboard':
      currentNav = 'dashboard';
      break;
    case '/insight':
      currentNav = 'insight';
      break;
    case '/aps':
      currentNav = 'aps';
      break;
    case '/settings':
      currentNav = 'settings';
      break;
    case '/tagTrigger':
      currentNav = 'settings';
      break;
    default:
      //Report error here later NOTE
      break;
  }

  // console.log(`current nav: ${currentNav}`);

  const [expand, setExpand] = useState(false);

  const [hover, setHover] = useState('');

  const [showHomeInfo, setShowHomeInfo] = useState(false);
  const [showDetailInfo, setShowDetailInfo] = useState(false);
  const [showInsiteInfo, setShowInsiteInfo] = useState(false);
  const [showAPSInfo, setShowAPSInfo] = useState(false);
  const [showSettingsInfo, setShowSettingsInfo] = useState(false);


  //While yes, css classes can be used, since there are multiple checks to add various parts, makes it easier to just have some styles be done manually here
  // const bg = { backgroundColor: "#EBF1F6" };

  const blueFilter = {
    filter:
      'brightness(0) saturate(100%)invert(6%) sepia(85%) saturate(2109%) hue-rotate(203deg) brightness(95%) contrast(106%)'
    // "brightness(0) saturate(100%) invert(24%) sepia(99%) saturate(1387%) hue-rotate(187deg) brightness(99%) contrast(101%)",
  };
  const blue = { color: '#0066B5' };

  const bg = { backgroundColor: 'rgb(236, 240, 245)' };
  const [hideText, setHideText] = useState(true);
  const [hideAps, setHideAps] = useState(false);

  let settingsAllowed = false;
  let liveViewAllowed = false;
  let dashboardAllowed = false;

  let dashboardInactive = true;

  if (user) {
    if (user.Features) {
      // console.log(user.Features);
      // settingsAllowed = user.Features.includes("settings");
      liveViewAllowed = user.Features.includes('Live View');
      dashboardAllowed = user.Features.includes('DashBoard');
      settingsAllowed = user.globalAdmin || user.userAdmin || user.LicensePool === 'Ultimate';
      // console.log(settingsAllowed, liveViewAllowed, dashboardAllowed);
    }
    if (user.featureLimits) {
      if (user.featureLimits.dashboard) {
        dashboardInactive = !user.featureLimits.dashboard;
      }
    }
  }

  //Used to animate the text fading out (remove the text after it is done fading)
  useEffect(() => {
    const getApsLastRefreshTime = async () => {
      // await helper.sleep(2000) // fix for the bug that the aps tab is not showing up when the connection is successful for the first time
      let res = await helper.GET(accessToken, controllerURLs.getApsLastRefreshTime);
      // console.log(res);
      if (res?.data?.length > 0) {
        setHideAps(false);
      }
      setRefreshSidebar(false);
    };
    if (refreshSidebar && controllerURLs.getApsLastRefreshTime) {
      getApsLastRefreshTime();
    }

    if (!expand) {
      let interval = setInterval(() => {
        setHideText(true);
      }, 1500);
      return () => clearInterval(interval);
    } else {
      setHideText(false);
    }
  }, [expand, accessToken, controllerURLs, refreshSidebar, setRefreshSidebar, hideAps]);

  const tabAllowed = (name) => {
    switch (name) {
      case 'home':
        return true;
      case 'factory':
        return liveViewAllowed;
      case 'insight':
        return dashboardAllowed;
      case 'aps':
        return dashboardAllowed;
      case 'settings':
        return settingsAllowed;
      default:
        console.error('unknown tab name');
        return false;
    }
  }

  return {
    location,
    currentNav,
    expand,
    setExpand,
    hover,
    setHover,
    blueFilter,
    blue,
    bg,
    hideText,
    setHideText,
    settingsAllowed,
    liveViewAllowed,
    dashboardAllowed,
    dashboardInactive,
    hideAps,
    refreshSidebar,
    showHomeInfo, setShowHomeInfo,
    showDetailInfo, setShowDetailInfo,
    showInsiteInfo, setShowInsiteInfo,
    showAPSInfo, setShowAPSInfo,
    showSettingsInfo, setShowSettingsInfo,
    tabAllowed,
    TABS
  };
}
