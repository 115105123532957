/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import useMsal from '../components/middle/useMsal.jsx';
// import useIsAuthenticated from '../components/middle/useIsAuthenticated.jsx';

import { getSessionToken, refreshSessionToken } from './sessionToken.js';
import applyUserRoles from './applyUserRoles.js';
import helper from '../util/helper.js';

export default function useWebAuth({ CLIENT, location, navigate, ROUTES, CONTROLLER_URLS }) {
  // if accessToken and controllerURLs are both defined (app ready to call apis)
  const [appReady, setAppReady] = useState(false);

  //microsoft auth library stuff
  const { instance, accounts } = useMsal();

  //is user Authenticatied with our app (not just logged in with microsoft)
  const [authenticated, setAuthenticated] = useState(false);

  //access token for logged in user
  const [accessToken, setAccessToken] = useState(null);
  const [accessTokenExpiry, setAccessTokenExpiry] = useState(null);
  const [accessTokenEmail, setAccessTokenEmail] = useState(null);
  const [msToken, setMsToken] = useState(null);

  //used for determining if its initial load and we checked account or not
  const [msAuthChecked, setMSAuthChecked] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  //is this the initial page load, only used inside token setting, kinda legacy

  const [properUser, setProperUser] = useState(null);
  const [needRedirect, setNeedRedirect] = useState(false); //used to indicate if user needs to be redirected to UNAUTHORIZED page
  const [needRedirectLogin, setNeedRedirectLogin] = useState(false);
  const [activeLogOut, setActiveLogOut] = useState(false);
  const [forceUserRefresh, setForceUserRefresh] = useState(false);
  const [forceUnauth, setForceUnauth] = useState(false);
  const [URLs, setURLs] = useState({});
  const [forceSignOut, setForceSignOut] = useState(false);//if true, and max number of signins exceeded, this will control whether to force unsign
  const [promptForceSignOut, setPromptForceSignOut] = useState(false);//whether to prompt user (on login screen) to sign out old session
  const fetching = useRef(false);

  const getToken = async () => {
    if (fetching.current) return;
    else fetching.current = true;

    const accessTokenRequest = {
      scopes: ['api://e2a5b007-70de-468e-8762-6fca2091a8d6/access_as_user'],
      account: accounts[0],
      forceRefresh: true,
      redirectUri: `${window.location.origin}/redirect.html`
    };

    // refresh wont work if token expired - just redirect to login page
    try {
      const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest); // microsoft token
      let newAccessToken = accessTokenResponse.accessToken;
      let expiresOn = accessTokenResponse.expiresOn;

      if (new Date() > expiresOn) {
        throw new Error('token expired');
      }
      const properToken = `Bearer ${newAccessToken}`;
      console.log('requesting new access token');
      let fetchToken = false;
      let sessionToken = JSON.parse(localStorage.getItem('sessionToken'));
      if (sessionToken) {
        const expiry = new Date(sessionToken.expiryDate);
        if (new Date() > expiry) fetchToken = true;
      } else {
        fetchToken = true;
      }

      if (fetchToken) {
        sessionToken = await getSessionToken({ CONTROLLER_URLS, msToken: properToken });
      }

      // TODO: should later look into using a session token, cookie, etc whichever is most secure
      localStorage.setItem('sessionToken', JSON.stringify(sessionToken));

      if (sessionToken === "too many current sign-ons" && forceSignOut) {
        /**
         * TODO: add logic to get user to confirm this
         */

        // try again forcing to delete previous sign ins
        console.log('requesting new access token - deleting previous sessions')
        sessionToken = await getSessionToken({ CONTROLLER_URLS, msToken: properToken, forceDelete: true });
      }



      console.log('session token: ', sessionToken);
      setMsToken(properToken);

      if (sessionToken === "too many current sign-ons") {
        setPromptForceSignOut(true);
      }
      else {
        setAccessTokenExpiry(new Date(sessionToken.expiryDate));
        setAccessToken(sessionToken.token);
        setAccessTokenEmail(sessionToken.email);
        localStorage.setItem('sessionToken', JSON.stringify(sessionToken)); // TODO: this should fix refresh issue if it comes up again
      }
    } catch (err) {
      console.error('caught error getting token, redirecting to login page');
      console.error(err);
      if (authenticated) {
        setAuthenticated(false);
        setAccessToken(null);
      }

      if (location.pathname !== '/login' && initialLoad) {
        setAuthenticated(false);
        navigate('/login');
      }
    }

    if (initialLoad) {
      setInitialLoad(false);
    }
    fetching.current = false;
  };

  const refreshToken = async () => {
    if (fetching.current) return;
    else fetching.current = true;

    console.log('refreshing token...')

    let sessionToken = await refreshSessionToken({ sessionToken: accessToken });
    if (!sessionToken?.authorised) {
      console.log('issue refreshing token: ', sessionToken);
      setAccessTokenExpiry(new Date(null));
      setAccessToken(null);
      fetching.current = false;
      return;
    }
    console.log('updating access token expiry: ', new Date(sessionToken.expiryDate));
    setAccessTokenExpiry(new Date(sessionToken.expiryDate));
    fetching.current = false;
  }

  const getUser = async () => {
    if (fetching.current) return;
    else fetching.current = true;

    try {
      console.log('fetching user...');
      const buildMode = window.location.href.includes('localhost');
      let tempUrl = buildMode
        ? 'https://localhost:10605/LTSAPI/Controller/1.0/user'
        : 'https://www.ltsdev.com:8605/LTSAPI/Controller/1.0/user';

      let currDate = new Date();
      let endDate = new Date(currDate - helper.timeInMS.month);
      let url = tempUrl;
      url += `?start=${endDate.toISOString()}`;
      url += `&end=${currDate.toISOString()}`;
      url += `&notified=-1`; //only get un seen notifications

      let user = await helper
        .GET(accessToken, url, true, {
          headers: {
            // LicenseID: 'FPK05-8MAO2-6TCBF-7QVWR-LIT3N',
            appdomain: window.location.origin,
            Authorization: accessToken,
            // MsAuth: msToken,
            email: accessTokenEmail,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })
        .catch((err) => {
          console.log(err);
        });
      if (!user || user?.authorised === false) {
        // backend doesnt have token anymore (max logins deleted or restarted)
        setAccessTokenExpiry(new Date(null));
        setAccessToken(null);
        localStorage.removeItem('sessionToken');
        throw new Error('not authorised');
      }
      let temp = { ...user };

      let root = window.location.href.includes('localhost') ? 'https://localhost:10605' : temp.domain + ':8605';

      if (window.location.href.includes('13.92.3.63:3000')) {
        root = 'https://13.92.3.63:10605';
      }

      setControllerURLs(root, temp.domain, setURLs);

      temp.guest = temp.LicensePool === 'Guest';

      temp.globalAdmin = false;
      temp.userAdmin = false;

      if (temp?.AdminType?.length) {
        for (let i = 0; i < temp.AdminType.length; i++) {
          if (temp.AdminType[i] === 'GlobalAdmin') {
            temp.globalAdmin = true;
          } else if (temp.AdminType[i] === 'Administrator') {
            temp.userAdmin = true;
          }
        }
      }

      temp.featureLimits = applyUserRoles(temp); // limits users features based on roles
      setProperUser(temp);
      setAuthenticated(true);
      console.log('fetch user success ', temp);
    } catch (err) {
      console.error(err);
    }
    fetching.current = false;
  };

  function setControllerURLs(root, base, setURLS) {
    let controllerURLs = {};

    for (const [key, val] of Object.entries(CONTROLLER_URLS)) {
      if (key === 'assets') {
        // controllerURLs[key] = 'http://localhost:80' + val // base + val; // to use local /assets_stream/ folder
        controllerURLs[key] = base + val;
      } else {
        controllerURLs[key] = root + val;
      }
    }

    setURLS(controllerURLs);
  }



  // set appReady state
  useEffect(() => {
    if (accessToken && properUser && Object.keys(URLs).length > 0 && !appReady) {
      setAppReady(true);
    }
  }, [accessToken, URLs, appReady, properUser]);

  // get access token, get user, then sets controller urls
  useEffect(() => {
    const getAuthStuff = async () => {
      if (!accessToken) {
        getToken();
      }

      if ((accessToken && !properUser) || forceUserRefresh) {
        if (forceUserRefresh) setForceUserRefresh(false);
        getUser();
      }
      setMSAuthChecked(true);
    };

    if (appReady) return;
    else getAuthStuff();
  }, [
    forceSignOut,
    CLIENT.ANDROID_APP,
    CONTROLLER_URLS,
    accessToken,
    accounts,
    authenticated,
    forceUserRefresh,
    initialLoad,
    instance,
    location.pathname,
    msToken,
    navigate,
    properUser
  ]);

  //Determines if user is authorised to view the page
  useEffect(() => {
    if (properUser && authenticated && properUser?.Features) {
      let page = location.pathname;

      if (properUser.Features.length === 0) {
        console.log('realy should be here!');
        setAuthenticated(false);
        setForceUnauth(true);
      } else if (page === '/dashboard' && !properUser.featureLimits.dashboard) {
        console.log('unauthorised dashboard!');
        setAuthenticated(false);
        setForceUnauth(true);
      } else if (page === '/details' && !properUser.Features.includes('Live View')) {
        console.log('unauthorised!');
        setAuthenticated(false);
        setForceUnauth(true);
      } else if (
        page === '/settings' &&
        !properUser.globalAdmin &&
        !properUser.userAdmin &&
        !properUser.LicensePool === 'Ultimate'
      ) {
        console.log('unauthorised!');
        setAuthenticated(false);
        setForceUnauth(true);
      }

      /*
      Possible options: 'Live View', 'DashBoard', 'Trajectory Heat Map', 'Data Export', 'Plant Simulation', 'User'
  
      / -> landing page, everyone who has live view is able to see it?
      /detail -> live map view <- Live View
      /dashboard -> graphs (also has export data) <-  ???
      /settings -> external db, area creator, error logs, user settings <- ????
      */
    }
  }, [properUser, location, authenticated]);

  //Used to redirect user to unauthorised page if they login with an invalid microsoft account for our services
  useEffect(() => {
    if (!msAuthChecked || initialLoad || fetching.current) return;
    if (appReady) return;
    if (!authenticated && location.pathname !== '/unauthorised' && location.pathname !== '/login') {
      setNeedRedirect(true);
    }

    if (!authenticated && location.pathname === '/' && needRedirect) {
      setNeedRedirect(false);
      setInitialLoad(true);
      navigate('/login');
    }

    if (forceUnauth) {
      setNeedRedirect(false);
      setInitialLoad(true);
      console.log(`redirecting to un auth force`);
      navigate('/unauthorised');
    }
    if (forceUnauth && location.pathname === '/unauthorised') {
      setForceUnauth(false);
      console.log(`set un auth force false`);
    }

    if (activeLogOut && location.pathname === '/login') {
      setActiveLogOut(false);
    }
    if (needRedirectLogin && !activeLogOut) {
      console.log(`redirected to login`);
      setNeedRedirectLogin(false);
      setInitialLoad(true);
      navigate('/login');
    }
    // if page doesnt exist, will redirect to 404 page
    else if (!authenticated && ROUTES.includes(location.pathname) && needRedirect && !activeLogOut) {
      console.log(`redirected to unauth`);
      console.log(location.pathname);
      console.log(needRedirectLogin);
      setNeedRedirect(false);
      setInitialLoad(true);
      navigate('/login');
    }
  }, [
    msAuthChecked,
    needRedirect,
    authenticated,
    location,
    forceUnauth,
    activeLogOut,
    needRedirectLogin,
    ROUTES,
    navigate,
    initialLoad,
    appReady
  ]);

  // interval to check accessToken expiry
  useEffect(() => {
    // Function to check if the access token is expired
    const checkTokenExpire = () => {
      const now = new Date();
      if (!accessToken) {
        getToken();
      }
      else if ((accessTokenExpiry - now < helper.timeInMS.minute)) {
        refreshToken();
      } else return;
    };

    if (location.pathname === '/login') return;
    const interval = setInterval(checkTokenExpire, helper.timeInMS.second * 5);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [accessToken, accessTokenExpiry]);

  // Called in LoginPage,  Opens the microsoft auth pop-up to login
  const login = function () {
    try {
      instance
        .loginPopup({
          scopes: ['openid', 'user.read', 'profile'] // optional Array<string>
        })
        .then((res) => {
          if (res?.accessToken) {
            // setAccessToken(res.accessToken);
            setForceUserRefresh(true);
            setAuthenticated(true);
          } else {
            console.err('no token recieved');
            console.log(res);
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  if (appReady && location.pathname === '/login') {
    navigate('/');
  }

  const showBars = appReady && location.pathname !== '/login';
  const startPressedTagAlerts = appReady;
  const tabConfigPage = null; // only used in teams app
  const loginPageProps = { login };

  if (!CLIENT.WEB_CLIENT) {
    console.error('imported wrong auth hook');
    return;
  } else
    return {
      setForceSignOut,
      promptForceSignOut,
      authenticated,
      setAuthenticated,
      appReady,
      setAppReady,
      instance,
      accounts,
      accessTokenEmail,
      accessToken,
      setAccessToken,
      initialLoad,
      setInitialLoad,
      properUser,
      setProperUser,
      needRedirect,
      setNeedRedirect,
      needRedirectLogin,
      setNeedRedirectLogin,
      activeLogOut,
      setActiveLogOut,
      forceUserRefresh,
      setForceUserRefresh,
      forceUnauth,
      setForceUnauth,
      URLs,
      login,
      msToken,
      showBars,
      startPressedTagAlerts,
      tabConfigPage,
      loginPageProps,
      getToken
    };
}
