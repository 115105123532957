import { useEffect, useState, useRef } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';
// import helper from '../util/helper';

export default function useServerSentEvents({ URLs, accessToken, user, CLIENT }) {
  const serverSentEventsInitialized = useRef(false);
  const [userNotifications, setUserNotifications] = useState(null);

  // initialize connection to recieve Server Sent Events
  useEffect(() => {
    function setUp() {
      eventSource = new EventSourcePolyfill(URLs.serverSentEvents, {
        headers: {
          Authorization: accessToken,
          appdomain: window.location.origin,
          isteamsapp: CLIENT.TEAMS_APP ? true : false,
          // LicenseID: 'FPK05-8MAO2-6TCBF-7QVWR-LIT3N',
          'Content-Type': 'application/json',
          Accept: 'application/json',
          // "Access-Control-Allow-Origin": "*",
          email: user.Email
        }
      });
      eventSource.onopen = () => console.log('initialized SSE connection');
      eventSource.onerror = (e) => console.error('error: ', e);
      eventSource.addEventListener('userNotifications', async (e) => {
        try {
          const data = JSON.parse(e.data);
          if (data.error) {
            console.error('got error from user notifs');
          } else if (data.alerts) {
            setUserNotifications(JSON.parse(data.alerts));
          } else {
            console.log('blah');
          }
        } catch (e) {
          console.error('issue getting user notifications: ', e);
        }
      });
    }

    let eventSource;
    if (Object.keys(URLs).length && !serverSentEventsInitialized.current && user) {
      setUp();
    }

    // terminating the connection on component unmount
    return () => {
      if (eventSource) {
        console.log('closing SSE connection');
        eventSource.close();
      }
    };
  }, [CLIENT, URLs, accessToken, user]);

  return { userNotifications };
}
