import forge from 'node-forge';

import helper from '../util/helper';

// set up urls // TODO: since pm2 doesnt launch license server locally, just hardcoding to ltsdev
// const baseUrl = 'https://127.0.0.1:8611/LTSAPI';
const baseUrl = 'https://www.ltsdev.com:8611/LTSAPI';
const getPublicKeyUrl = `${baseUrl}/getPublicKey`;
const getSessionTokenUrl = `${baseUrl}/getSessionToken`;
const refreshTokenUrl = `${baseUrl}/refreshToken`;

// for encryption we need an RSA key and keyPair
const rsa = forge.pki.rsa;
let keyPair = rsa.generateKeyPair({ bits: 2092, e: 0x10001 });

async function getSessionToken({ msToken, isTeamsApp, forceDelete }) {
  // get public key from license server
  const serverPublicKey = await helper.GET(null, getPublicKeyUrl);
  if (!serverPublicKey) {
    throw new Error('error getting public key \n check license server running?');
  }
  // console.log('server public key: ', serverPublicKey);

  // we want to encrypt using the servers private key
  keyPair.publicKey.e.data = serverPublicKey.e.data;
  keyPair.publicKey.e.s = serverPublicKey.e.s;
  keyPair.publicKey.e.t = serverPublicKey.e.t;
  keyPair.publicKey.n.data = serverPublicKey.n.data;
  keyPair.publicKey.n.s = serverPublicKey.n.s;
  keyPair.publicKey.n.t = serverPublicKey.n.t;

  // generate cipher key
  const kdf1 = new forge.kem.kdf1(forge.md.sha1.create());
  const kem = forge.kem.rsa.create(kdf1);
  const cipherKey = kem.encrypt(keyPair.publicKey, 16);
  const cipherKey64 = forge.util.encode64(cipherKey.encapsulation);

  // encrypt microsoft token using AES-GCM
  const iv = forge.random.getBytesSync(12);
  const iv64 = forge.util.encode64(iv);
  const cipher = forge.cipher.createCipher('AES-GCM', cipherKey.key);
  cipher.start({ iv: iv });
  cipher.update(forge.util.createBuffer(msToken));
  cipher.finish();
  const encryptedToken = cipher.output.getBytes();
  const encryptedToken64 = forge.util.encode64(encryptedToken);
  const tag = cipher.mode.tag.getBytes();
  const tag64 = forge.util.encode64(tag);

  const res = await helper.GET(null, getSessionTokenUrl, false, {
    headers: {
      authorization: encryptedToken64,
      iv: iv64,
      tag: tag64,
      key: cipherKey64,
      'Content-Type': 'application/json',
      appdomain: window.location.origin,
      isteamsapp: isTeamsApp ? true : false,
      forceDelete: forceDelete ?? false
    }
  });
  if (!res?.success || !res?.sessionToken) {
    if (res?.error === 'too many current sign-ons' && !forceDelete) {
      return res.error;
    }
    throw new Error('error getting session token');
  }
  return res.sessionToken;
}

async function refreshSessionToken({ sessionToken }) {
  if (!sessionToken) throw new Error('no session token');
  return await helper.GET(null, refreshTokenUrl, false, {
    headers: {
      authorization: sessionToken,
      'Content-Type': 'application/json',
    }
  });
}

export {
  getSessionToken,
  refreshSessionToken
}
