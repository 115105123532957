import { lazy, useContext } from 'react';

import AppContext from '../../../context/AppContext';

const TopbarDesktop = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "LoginFormDesktop" */ './desktop/Topbar')
);
const TopbarMobile = lazy(() =>
  import(/* webpackPrefetch: true, webpackChunkName: "LoginFormMobile" */ './mobile/Topbar')
);

export default function Topbar({
  account,
  instance,
  activeNotif,
  fakeNotif,
  setFakeNotif,
  notificationsExpand,
  setNotificationsExpand
}) {
  const { mediaQueries } = useContext(AppContext);
  if (mediaQueries.isTabletOrMobile)
    return (
      <TopbarMobile
        account={account}
        instance={instance}
        activeNotif={activeNotif}
        fakeNotif={fakeNotif}
        setFakeNotif={setFakeNotif}
        notificationsExpand={notificationsExpand}
        setNotificationsExpand={setNotificationsExpand}
      />
    );
  else
    return (
      <TopbarDesktop
        account={account}
        instance={instance}
        activeNotif={activeNotif}
        fakeNotif={fakeNotif}
        setFakeNotif={setFakeNotif}
        notificationsExpand={notificationsExpand}
        setNotificationsExpand={setNotificationsExpand}
      />
    );
}
