/**
 * Context for state variables available throughout entire app
 * 
 * example usage:
 * const appContext = useContext(AppContext);
 */

import React from 'react';

const AppContext = React.createContext();

export default AppContext;
